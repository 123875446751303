main.main{
	background: $white;
	min-height: 100vh;
}

.fheight{
	min-height: 100vh;
	@include media-breakpoint-down(lg){
		padding: 0 15px;
	}
	@include media-breakpoint-down(sm){
		min-height: calc(100vh - 100px);
	}
}

.hheight{
	min-height: 50vh;
	@include media-breakpoint-down(lg){
		padding: 0 15px;
	}
}
.intro{
	position: relative;
	.image-background{
		background-image: url('../img/image-hero-back.jpg');
		background-size: cover;
		background-position: 50% 50%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.first-half{
		position: relative;
		text-align: center;
		img{
			max-width: 100%;
			height: auto;
		}
		.img-cont{
			margin: 0 auto;
			padding: 40px;
		    max-width: 800px;
			@include media-breakpoint-up(lg){
				margin-top: 40px;
			}

		    @include media-breakpoint-down(sm){
		    	padding: 20px;
		    	margin-bottom: 60px;
		    }
		}
	}
	.second-half{
		position: relative;
	}
	.overlay{
		height: 50vh;
		position: relative;
		@include gradient(to bottom, rgba(darken($black,40%), 0.35), rgba($black, 0.25));
		color: $white;

	@include media-breakpoint-down(sm){
		height: calc(50vh - 100px);
	}

		.hheight{
			&>div{
				max-width: 800px;
				margin: 35px auto;
				text-align: center;
			}
			h2{
				color: $white;
				font-weight: 100;
				font-size: 2rem;
				@include media-breakpoint-down(md){
					text-align: center;
				}
				@include media-breakpoint-down(sm){
					font-size: 1.5rem;
				}
			}

		}

		@-webkit-keyframes ani-mouse {
			0% {
			opacity: 1;
			top: 29%;
			}
			15% {
			opacity: 1;
			top: 50%;
			}
			50% {
			opacity: 0;
			top: 50%;
			}
			100% {
			opacity: 0;
			top: 29%;
			}
		}
		@-moz-keyframes ani-mouse {
			0% {
			opacity: 1;
			top: 29%;
			}
			15% {
			opacity: 1;
			top: 50%;
			}
			50% {
			opacity: 0;
			top: 50%;
			}
			100% {
			opacity: 0;
			top: 29%;
			}
		}
		@keyframes ani-mouse {
			0% {
			opacity: 1;
			top: 29%;
			}
			15% {
			opacity: 1;
			top: 50%;
			}
			50% {
			opacity: 0;
			top: 50%;
			}
			100% {
			opacity: 0;
			top: 29%;
			}
		}
		 .scroll-btn {
			display: block;
			position: absolute;
			left: 50%;
			bottom: 36px;
			text-align: center;
			color: $white;
			@include transform(translateX(-50%));
			@media (max-height: 750px){
				display: none;
			}
		}
		.scroll-btn > * {
			line-height: 18px;
			font-size: 11px;
			font-weight: normal;
			letter-spacing: 2px;
			display: block;
		}

		.scroll-btn .mouse {
			position: relative;
			display: block;
			width: 20px;
			height: 30px;
			margin: 0 auto 10px;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			border: 1px solid white;
			border-radius: 23px;
		}
		.scroll-btn .mouse > * {
			position: absolute;
			display: block;
			top: 29%;
			left: 50%;
			@include transform(translateX(-52%));
			width: 3px;
			height: 3px;
			margin: -4px auto 0;
			background: white;
			border-radius: 50%;
			-webkit-animation: ani-mouse 2.5s linear infinite;
			-moz-animation: ani-mouse 2.5s linear infinite;
			animation: ani-mouse 2.5s linear infinite;
		}

	}
}

.companies{
	position: relative;
	a.box{
	    .row{
	    	height: 100%;
	    }
	    .content{
	    	background: #f5f5f5;
	    	.inner{
	    		padding: 80px 50px;
	    		height: 100%;

				@include media-breakpoint-down(md){
					padding: 50px 30px;
				}
				@include media-breakpoint-down(sm){
					padding: 30px 15px;
				}
	    		h3{
	    			color: #333;
	    			font-weight: 300;
	    		}
	    		p.intro{
	    			color: rgba(#333,0.8);
	    			padding-right: 50px;
	    			text-align: justify;
	    		}
	    	}
	    }
	    &:hover{
	    	.image_bg{
	    		@include transform(scale(1.15,1.15));
	    	}
	    	.overlay{
	    		@include opacity(1);
	    		.arrow{
	    			@include opacity(1);
	    			@include transform(translate(-50%,-50%));
	    		}
	    	}
	    }
	}
	.image{
		overflow: hidden;
		@include media-breakpoint-down(md){
			min-height: 200px;
		}



		.image_bg{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;
			@include transition(all .5s cubic-bezier(0.51, 0.11, 0.3, 0.84));
			background-size: cover;
			background-position: 50% 50%;
			background-repeat: no-repeat;
		}
		.overlay{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			overflow: hidden;
			@include transition(all .5s cubic-bezier(0.51, 0.11, 0.3, 0.84));
			background: rgba(darken($brand-primary,50%), 0.8);
			@include gradient(to bottom, rgba(darken($black,40%), 0.4), rgba($black, 0.3));
			@include opacity(0);
			.arrow{
				position: absolute;
				@include opacity(0);
				top: 50%;
				left: 50%;
				@include transform(translate(-100%,-50%));
				display: block;
				width: 85px;
				height: 85px;
				@include transition(all .3s .4s cubic-bezier(0.51, 0.11, 0.3, 0.84));
				svg{
					width: 100%;
					height: auto;
				}
			}
		}
	}
}
.timeline{
	background: #f6f6f6;
	padding: 80px;
	@include media-breakpoint-down(md){
		padding: 45px;
	}
		@include media-breakpoint-down(sm){
			padding: 30px;
		}

	.ag-timeline-content{
		box-shadow: 0 14px 34px -10px rgba(0,0,0,.1), 0 14px 34px -10px rgba(0,0,0,.1);
	}
}


.map-ag{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}


.phrase{
	background: #0b2137;
	padding: 80px;
    @include gradient(to bottom,rgba(0,0,0,.4),rgba(55,56,60,.3));
    text-align: center;
    span{
    	color: $white;
    	font-weight: 100;
    	font-size: 2rem;
    	@include media-breakpoint-down(md){
    		font-size: 1.7rem;
    	}
    }
}

.about{
	.title{
    background: rgba(11,33,55,1);
    background-image: linear-gradient(to bottom,rgba(0,0,0,.4),rgba(55,56,60,.3));
		color: $white;
		padding: 65px;
		padding-bottom: 40px;
		@include media-breakpoint-down(md){
			padding: 30px;
		}
		@include media-breakpoint-down(sm){
			padding: 15px;
		}
		h3{
			@include media-breakpoint-down(sm){
				text-align: center;
			}
		}
		h4{
			font-weight: 100;
			font-size: 1.5rem;
			line-height: 1.95rem;
			margin-top: 25px;
			@include media-breakpoint-down(sm){
				text-align: center;
			}
		}
	}
	ul.nav{
		margin: 0 0;
		li{
			padding: 0 0;
			text-align: center;
			a{
				background: rgba(11,33,55,1);
				padding: 20px 26px;
				font-size: 1.2rem;
				font-weight: 500;
				color: $white;

				@include media-breakpoint-down(md){
					font-size: 0.9rem;
					padding: 20px 5px;
				}

				&:hover{
					letter-spacing: 1px;
				}
				&.active{
					background: rgba(11,33,55, 0.9);
				}
			}
		}
	}
	.tab-pane{
		padding: 40px 65px;
		color: rgba(51,51,51,.8);
		padding-right: 50px;
		text-align: justify;
		@include media-breakpoint-down(md){
			padding: 30px;
		}
		@include media-breakpoint-down(sm){
			padding: 15px;
		}

		span.year{
			font-weight: 600;
			color: rgba(11,33,55,1);
			display: block;
			margin-bottom: 10px;
			font-size: 1.2rem;
		}
		figure{
			text-align: center;
			a{
				display: inline-block;
				text-align: center;
			}
			img{
				max-height: 100&;
				height: auto;
				margin: 0 auto;
			}

		}
	}
}

.timeline{
	overflow: hidden;
	position: relative;
	.ag-timeline{
		padding: 0 0;
	}
	h3{
		color: rgba(11,33,55,1);
		font-size: 2rem;
		margin-bottom: 35px;

		@include media-breakpoint-down(md){
			width: 100%;
			text-align: center;
		}
	}
	.ag-date{
	    font-weight: 600;
	    color: #0b2137;
	    display: block;
	    margin-bottom: 10px;
	    font-size: 1.2rem;
	}
	span{
		font-weight: 600;
	}
}

.contact{
	.locations{
		background: #0b2137;
    	background-image: linear-gradient(to bottom,rgba(0,0,0,.4),rgba(55,56,60,.3));
		padding: 65px;
		padding-bottom: 10px;

		@include media-breakpoint-down(md){
			padding: 30px 45px;
		}
		@include media-breakpoint-down(sm){
			padding: 30px;
		}
		h3{
			color: $white;
			margin-bottom: 60px;
		}
		ul{
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			color: $white;
			li{
				margin-bottom: 20px;
				h4{
					font-size: 1.2rem;
					font-weight: 100;
					display: inline-block;
					margin-right: 10px;
				}
				a{
					font-size: 1rem;
					&:hover{
						color: rgba($brand-primary,0.5);
					}
				}
				&.telephones{
					margin-bottom: 0px;
					font-size: 1.4rem;
					
					&.first{
						margin-top: 30px;
					}
					h4{
						display: inline-block;
						margin-right: 10px;
					}
				}
			}
		}
	}
	.address{
		color: $white;
		margin-bottom: 50px;
		font-size: 1.35rem;
		margin-top: 45px;
		a{
			font-size: 1rem;
					&:hover{
						color: rgba($brand-primary,0.5);
					}
		}
	}
	.copyright{
		margin-top: 10vh;
		color: rgba($white,0.85);
		font-size: 0.85em;
	}
}

.ashton-old{
	text-align: right;
	@include media-breakpoint-down(md){
		margin: 30px auto;
		text-align: center;
	}
	img{
		max-width: 200px;
	}
}