*{
	-webkit-font-smoothing: antialiased;
}

body{
	font-family: "acumin-pro",sans-serif;
	overflow-x: hidden;
	letter-spacing: 0.01em;
	color: $black;
}
#wrap{
    overflow-x: hidden;
}
a{
	@include transition(all .5s cubic-bezier(0.51, 0.11, 0.3, 0.84));
	&:hover{
		text-decoration: none;
		outline: 0;
	}
	&:focus, &:active {
		text-decoration: none;
		outline: 0;
	}
}

p{
	&:last-child{
		margin-bottom:0;
	}
}
img{
	pointer-events: none;
    max-width: 100%;
    height: auto;
}

input, .btn {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    &[type=checkbox]{
    -webkit-appearance: checkbox;
    }
}


.sr-only{
    display: none;
}

.nopad{
	padding-left: 0;
	padding-right: 0;
}

main.main{
	@include media-breakpoint-down(sm){
		margin-top: 104px;
	}
}

.iexplorer{
	.companies{
		.d-flex{
			display: block !important;
		}	
	}
	.intro{
		.fheight{
			&>div{
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				@include transform(translate(-50%,-50%));
			}
		}
	}
}



.fade-in{
	@include opacity(0);
	@include transition(all 1s ease-in-out);
	&.show{
		@include opacity(1);
		@include transition(all 1s ease-in-out);
	}
}
