// Grid settings
$enable-flex:               true;
$enable-rounded:            false;
$enable-transitions:        true;
$enable-shadows:            true;

// Colors
$brand-primary:             #61a0e0;
$brand-success:             #61a0e0;
$brand-info:                #61a0e0;
$brand-warning:             #61a0e0;
$brand-danger:              #61a0e0;

$black: 				#37383c;
$white:					#fff;
$text: #6f6f6e;




// BOOTSTRAP

$container-max-widths: (
  sm: 800px,
  md: 1000px,
  lg: 1170px,
  xl: 1540px
);
$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 850px,
  lg: 1170px,
  xl: 1600px
);




// SVG background images with PNG and retina fallback

$image-path: '../img' !default;
$fallback-extension: 'png' !default;
$retina-suffix: '@2x';
@mixin background-image($name, $size:false){
    background-image: url(#{$image-path}/#{$name}.svg);
    @if($size){
        background-size: $size;
    }
    .no-svg &{
        background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

        @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
          background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
        }
    }
}





@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}
@mixin box-shadow($shadow…) {
-webkit-box-shadow: $shadow;
-moz-box-shadow: $shadow;
box-shadow: $shadow;
}
@mixin transform-origin($args...) {
  -webkit-transform-origin: $args;
  -moz-transform-origin: $args;
  -ms-transform-origin: $args;
  -o-transform-origin: $args;
  transform-origin: $args;
}



@mixin gradient($args...) {
  background-image: -webkit-linear-gradient($args); 
  background-image:    -moz-linear-gradient($args); 
  background-image:     -ms-linear-gradient($args); 
  background-image:      -o-linear-gradient($args); 
  background-image:         linear-gradient($args);
}


@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}


%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// usage: @extend %clearfix;

// Visually hide an element

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}


