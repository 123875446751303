
.ag-timeline {
  position: relative;
  margin-top: 0;
}
.ag-timeline::before {
  content: '';
  position: absolute;
  top: -100vw;
  bottom: -100vw;
  left: 18px;
  height: 99999vh;
  width: 4px;
  background: #d7e4ed;
}
@media only screen and (min-width: 1170px) {
  .ag-timeline {
    margin-top: 0;
  }
  .ag-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}

.ag-timeline-block {
  position: relative;
  margin: 15px 0;
}
.ag-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.ag-timeline-block:first-child {
  margin-top: 0;
}
.ag-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {

  .ag-timeline-block:first-child {
    margin-top: 0;
  }
  .ag-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.ag-timeline-img {
  position: absolute;
  top: 20px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: rgba(11,33,55,1);
  @include media-breakpoint-down(md){
    left: 10px;
  }
}

@media only screen and (min-width: 1170px) {
  .ag-timeline-img {
    width: 20px;
    height: 20px;
    left: 50%;
    margin-left: -10px;
  }
  .cssanimations .ag-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .ag-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: ag-bounce-1 0.6s;
    -moz-animation: ag-bounce-1 0.6s;
    animation: ag-bounce-1 0.6s;
  }
}

@-webkit-keyframes ag-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes ag-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}
@keyframes ag-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.ag-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
}
.ag-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.ag-timeline-content h2 {
  color: #303e49;
}

.ag-timeline-content p {
  line-height: 1.6;
}
.ag-timeline-content .ag-read-more {
  float: right;
  padding: .8em 1em;
  background: #acb7c0;
  color: white;
  border-radius: 0.25em;
}
.no-touch .ag-timeline-content .ag-read-more:hover {
  background-color: #bac4cb;
}

.ag-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}
@media only screen and (min-width: 850px) {
  .ag-timeline-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .ag-timeline-content p {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1170px) {
  .ag-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .ag-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }
  .ag-timeline-content .ag-read-more {
    float: left;
  }
  .ag-timeline-block:nth-child(even) .ag-timeline-content {
    float: right;
  }
  .ag-timeline-block:nth-child(even) {
    top: -50px;
    margin-bottom: -110px;
  }
  .ag-timeline-block:nth-child(even) .ag-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }
  .ag-timeline-block:nth-child(even) .ag-timeline-content .ag-read-more {
    float: right;
  }

  .cssanimations .ag-timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .ag-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: ag-bounce-2 0.6s;
    -moz-animation: ag-bounce-2 0.6s;
    animation: ag-bounce-2 0.6s;
  }
}

@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .cssanimations .ag-timeline-block:nth-child(even) .ag-timeline-content.bounce-in {
    -webkit-animation: ag-bounce-2-inverse 0.6s;
    -moz-animation: ag-bounce-2-inverse 0.6s;
    animation: ag-bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes ag-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes ag-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes ag-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes ag-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes ag-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes ag-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
