.sidebar{
	position: fixed;
	top: 0;
	bottom: 0;
	background: #f3f3f3;
	-webkit-flex-direction: column;
	flex-direction: column;
	box-shadow: 0 14px 34px -10px rgba(0,0,0,.1), 0 14px 34px -10px rgba(0,0,0,.1);
	z-index: 9;

	@include media-breakpoint-down(sm){
		width: 100%;
		bottom: auto;
		z-index: 9999;
		box-shadow: none;
		background: $white;
	}
	.logo{
		display: block;
		background: $white;
		width: 100%;
		max-height: 250px;
		@include opacity(1);
		&.hide{
			@include opacity(0);
		}
		.inner{
			text-align: center;
			padding: 40px 80px;
			@include media-breakpoint-down(md){
				padding: 10px;
			}
			img{
				margin: 0 auto;
				max-width: 100%;
				width: 200px;
				height: auto;

			}
		}
		@include media-breakpoint-down(sm){
			width: auto;
			display: inline-block !important;
			.inner{
				text-align: left;
			}
			img{
				margin: 0 0;
				width: 100px !important;
			}
		}


	}
	.menu{
		width: 100%;
  -webkit-box-flex: 2;      /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex:2;         /* OLD - Firefox 19- */
  -webkit-flex: 2;          /* Chrome */
  -ms-flex: 2;
		flex: 2;
		@include media-breakpoint-down(sm){
			display: none !important;
		}
	}
	ul.nav{
		width: 100%;
		-webkit-flex-direction: column;
		flex-direction: column;
		height: 100%;
		li.nav-item{
			padding: 0;
			text-align: center;
			border-top: 1px solid rgba($black,0.1);

  -webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex:1;         /* OLD - Firefox 19- */
  -webkit-flex: 1;          /* Chrome */
  -ms-flex: 1;
			flex: 1;

			&:first-child{
				border-top: 0;
			}
			a{
				font-size: 1.1rem;
				color: $black;
				position: relative;
				width: 100%;
				font-weight: 500;
				min-height: 62px;
				@include transition(all 0.3s cubic-bezier(0.51, 0.11, 0.3, 0.84));
				span{
					position: absolute;
					top: 50%;
					left: 50%;
					@include transform(translate(-50%,-50%));
				}
				&:hover{
					background: darken(#f3f3f3,7%);
					letter-spacing: 2px;
				}
			}
		}

	}
}
.mb-menu{
	display: none;
	background: $white;
	color: $black;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	.mb-container{
		height: 100%;
	}

	.nav-menu{
		text-align: center;
		display: -webkit-box;
		display: -webkit-flex;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-flex-direction: row;
	    -ms-flex-direction: row;
	    flex-direction: column;
	    -webkit-justify-content: space-between;
	    -ms-flex-pack: justify;
	    justify-content: space-between;
	    height: calc(100% - 50px);
	    list-style: none;
	    padding-left: 0;
		padding-top: 120px;
	 	padding-bottom: 50px;
	 	width: 100vw;

		li{
			margin: 17px 0;

			a{
				border-bottom: 0;
				padding: 0 0;
				font-size: 1.35em;
				color: $black;
				@include transition(all 700ms cubic-bezier(0.19, 1, 0.22, 1));
				&:hover, &:active, &:focus{
					background-color: transparent;
					color: $black;
				}

			}

		}
	}

	&.opened{
		display: block !important;
		z-index: 999;
	}

}
	.menu-mobile{
		display: none;
		@include media-breakpoint-down(sm){
			display: block;
		    position: fixed;
		    right: 5vw;
		    top: 28px;
		    z-index: 9999;
		}
	}

// MENU MOBILE

	.menu-button{
		position: relative;
		width: 33px;
		height: 33px;
		padding: 0;
		cursor: pointer;
		border: none;
		top: 11px;
		margin: 0 5px;
		outline: none;
		background: transparent;
		@include transform(all 300ms cubic-bezier(0.19, 1, 0.22, 1));

	}
	.menu-button::before,
	.menu-button::after,
	.menu-button span {
		background: $black;
	}

	.menu-button--open::before,
	.menu-button--open::after,
	.menu-button--open span {
		background: $black;
	}
	.menu-button::before,
	.menu-button::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 1px;
		pointer-events: none;
		@include transition(transform 0.25s);
		-moz-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
	.menu-button span {
		position: absolute;
		left: 0;
		overflow: hidden;
		width: 100%;
		height: 1px;
	    top: 17px;
		text-indent: 99999;
		@include transition(opacity 0.25s);
	}
	.menu-button::before {
		@include transform(translate3d(0, -10px, 0) scale3d(0.8, 1, 1));
	}
	.menu-button::after {
		@include transform(translate3d(0, 10px, 0) scale3d(0.8, 1, 1));
	}
	.menu-button--open span {
		@include opacity(0);
	}
	.menu-button--open::before {
		@include transform(rotate3d(0, 0, 1, 45deg));
	}
	.menu-button--open::after {
		@include transform(rotate3d(0, 0, 1, -45deg));
	}


